.form {
  max-width: 550px;
  width: 100%;
  padding: 40px 32px;
  border-radius: 32px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  background-color: var(--white);
  box-shadow: 8px 16px 12px 0px #12121214;
  align-items: center;
  color: var(--text-primary);
}
.innerForm {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.inputContainer {
  height: 64px;
  padding: 20px 24px;
  border: 1px solid var(--stroke-buttons-input);
  border-radius: 16px;
  display: flex;
  align-items: center;
  gap: 12px;
}

.inputContainer input,
.contactInputContainer input,
.inputContainer select,
.contactInputContainer select {
  border: none;
  outline: none;
  flex: 1;
}

.error {
  color: red;
  font-size: 0.875rem;
  margin-top: 0.25rem;
}

.submitButton {
  border-radius: 16px;
  gap: 12px;
  padding: 16px 24px;
  font-size: var(--font-size-h6);
  font-weight: var(--font-weight-600);
  line-height: var(--line-height-24);
  letter-spacing: -0.2px;
}
.btnIconBox {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: var(--white);
  width: 32px;
  height: 32px;
  border-radius: 12px;
}
.contactInputContainer {
  width: 100%;
  gap: 0;
  display: flex;
  align-items: center;
  height: 64px;
  padding: 20px 24px;
  border: 1px solid var(--stroke-buttons-input);
  border-radius: 16px;
}
.separatorLine {
  display: inline-block;
  width: 1px;
  height: 64px;
  background-color: var(--stroke-buttons-input);
  margin: 0 20px 0px 16px;
}
.selectEmployee {
  width: 100%;
}
.w100 {
  width: 100%;
}
.selectCountry {
  width: 50%;
  flex-shrink: 0;
  min-width: 92px;
}
.selectEmployee div,
.selectCountry div {
  border: none !important;
}
.selectEmployee:focus-visible div,
.selectCountry div {
  border: none !important;
}
.countryCodeOption {
  display: flex;
  align-items: center;
  gap: 8px;
}
.countryName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: auto;
  max-width: 100%;
  display: inline-block;
}

.phoneNumberContainer {
  width: 50%;
  display: flex;
  align-items: center;
  gap: 12px;
}
.notes {
  width: 100%;
  height: 96px;
  resize: none;
  border: none;
  outline: none;
}
.pickSlotCard {
  background-color: var(--white);
  border-radius: 32px;
  max-width: 550px;
  width: 100%;
  min-height: 624px;
  height: auto;
  padding: 40px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 8px 12px 24px 0px #12121214;
  text-align: center;
}
.pickSlotCard p {
  color: var(--text-secondary);
  text-align: center;
}
.bookedSlotIcon {
  margin-bottom: 40px;
}
.bookedDemoBtn {
  margin-top: 32px;
}
.ctaSupportingText {
  font-size: var(--font-size-h8);
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-18);
  letter-spacing: -0.2px;
  color: var(--text-secondary);
  margin-top: -16px;
}
.termsContainer {
  margin: 0 16px;
  color: var(--text-secondary);
  font-size: var(--line-height-14);
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-20);
  text-align: center;
}
.linkText {
  color: var(--p-50);
  text-decoration: underline;
}

@media screen and (max-width: 650px) {
  .pickSlotCard {
    max-width: 450px;
    width: calc(100% - 48px);
    max-height: 80vh;
    margin: 0 24px;
    text-align: center;
  }
}

@media screen and (max-width: 478px) {
  .contactInputContainer {
    flex-direction: column;
    height: auto;
    padding: 0;
    border: 1px solid var(--stroke-buttons-input);
    border-radius: 16px;
  }
  .selectCountry,
  .phoneNumberContainer {
    width: 100%;
    padding: 20px 24px;
    max-height: 64px;
    display: flex;
    align-items: center;
  }
  .separatorLine {
    width: 100%;
    height: 1px;
  }
}
